(function ($){
    "use strict";

    var $codes = $(".markdown");
    $codes.each(function(idx, el){
        var $el = $(el).removeClass("markdown");
        var $parent = $el.parent();
        $parent.wrap('<div class="snippet-box response from-md"><div class="snippet-body no-head"></div></div>');
    });
}(jQuery));
